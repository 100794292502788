import React from 'react'
import { useWindowSize } from 'hooks'
import { Text } from 'components/ui'
import styles from './advantage.module.scss'

interface IAdvantageProps {
  title: string
  description: string
}

const Advantage = ({ title, description }: IAdvantageProps) => {
  const { deviceWidth } = useWindowSize()

  return (
    <div className={styles.advantage}>
      <Text size='xl' family='secondary'>
        {title}
      </Text>
      <Text size={deviceWidth === 'small' ? 'sm' : 'lg'} whiteSpace='pre-line'>
        {description}
      </Text>
    </div>
  )
}

export default Advantage
