import React from 'react'
import { HashLink } from 'react-router-hash-link';
import { hashLinks } from "constants/links";
import { Text } from 'components/ui'
import styles from "./styles.module.scss";

interface IMenuLink {
  text: string
  link: string
}

const MenuLinks = () => (
  <div className={styles['menu-links']}>
    {Object.values(hashLinks).map((v: IMenuLink) => (
      <HashLink key={v.link} to={v.link} smooth>
        <Text size='md' color='blue'>
          {v.text}
        </Text>
      </HashLink>
    ))}
  </div>
)

export default MenuLinks
