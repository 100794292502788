import { Route, Routes } from 'react-router-dom'
import Layout from './components/layout/Main'
import Home from './views/Home'

const App = () => {
  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route path='*' element={<Home />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
