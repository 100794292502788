import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Button, Input, Modal, Text } from 'components/ui'
import styles from './callback-modal.module.scss'

interface CallbackModalProps {
  isOpen?: boolean
  closeHandler: VoidFunction
}

interface IFormValues {
  name: string
  phone: string
}

export const CallbackModal: React.FC<CallbackModalProps> = ({ isOpen = false, closeHandler }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormValues>()

  const submitForm = (data: IFormValues) => console.log(data)

  return (
    <Modal isOpen={isOpen} onRequestClose={closeHandler}>
      <div className={styles['callback-modal']}>
        <div className={styles['callback-modal__header']}>
          <Text as='h4' align='center'>
            Закажите обратный звонок
          </Text>
        </div>
        <div className={styles['callback-modal__content']}>
          <Text as='p' size='md' align='center' weight='regular'>
            Наш менеджер свяжется с вами<br />для расчета стоимости
          </Text>
          <form className='offset-top-32' onSubmit={handleSubmit(submitForm)}>
            <Input
              name='name'
              register={register}
              errors={errors}
              placeholder='Ваше имя'
              rules={{ required: { value: true, message: 'Обязательное поле' } }}
              fluid
              required
            />
            <Controller
              name='phone'
              control={control}
              rules={{
                required: { value: true, message: 'Обязательное поле' },
                pattern: { value: /\+\d{1}\s?\d{3}\s?\d{3}\s?\d{2}\s?\d{2}/, message: 'Неверный номер телефона' }
              }}
              render={({ field: { name, value, onChange } }) => (
                <Input
                  name={name}
                  type='tel'
                  value={value}
                  onChange={onChange}
                  classNameInputWrapper='offset-top-12'
                  errors={errors}
                  placeholder='Ваш телефон'
                  fluid
                  required
                />
              )}
            />
            <Button className='offset-top-44' type='submit'>
              Отправить
            </Button>
          </form>
        </div>
      </div>
    </Modal>
  )
}
