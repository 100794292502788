import cx from 'classnames'
import { EMAIL, RUSSIA_PHONE } from 'constants/const'
import { Text } from 'components/ui'
import telegramImg from 'assets/images/telegram.svg'
import whatsappImg from 'assets/images/whatsapp.svg'
import logoWhiteImg from 'assets/images/logo-white.svg'
import styles from './styles.module.scss'

export const Footer = () => (
  <div className={cx('offset-top-120', styles['footer-wrapper'])}>
    <footer className={styles.footer}>
      <div className={styles.footer__logo}>
        <img src={logoWhiteImg as string} alt='' />
      </div>
      <div className={styles.footer__content}>
        <div className={styles.left}>
          <div className={styles.addresses}>
            <div className={styles.addresses__item}>
              <Text as='p' color='white' size='sm'>
                Адрес склада в Стамбуле:<br />İstoç 26. Ada No:136 Bağcılar/ İstanbul
              </Text>
              <div className='offset-top-16'>
                {/*<a href={`tel:${TURKEY_PHONE}`}>*/}
                {/*  <Text as='p' size='sm' color='white'>*/}
                {/*    {TURKEY_PHONE}*/}
                {/*  </Text>*/}
                {/*</a>*/}
                <a href={`tel:${RUSSIA_PHONE}`}>
                  <Text as='p' size='sm' color='white'>
                    {RUSSIA_PHONE}
                  </Text>
                </a>
              </div>
            </div>
            <div className={styles.addresses__item}>
              <Text as='p' color='white' size='sm'>
                Адрес склада в Вьетнаме:<br />Truong Son Street, Ward 2, Tan Binh District, Ho Chi Minh City
              </Text>
              <div className='offset-top-16'>
                <a href={`tel:${RUSSIA_PHONE}`}>
                  <Text as='p' size='sm' color='white'>
                    {RUSSIA_PHONE}
                  </Text>
                </a>
              </div>
            </div>
          </div>
          <Text className='offset-top-72 offset-md-top-64' as='p' color='gray' size='xs'>
            © 2024 Курьерская служба ParcelGet
          </Text>
        </div>
        <div className={styles.right}>
          <a href={`mailto:${EMAIL}`} target='_blank'>
            <Text as='p' size='sm' color='white'>
              {EMAIL}
            </Text>
          </a>
          <div className={styles.socials}>
            <img src={whatsappImg as string} alt='whatsapp-link' />
            <img src={telegramImg as string} alt='telegram-link' />
          </div>
        </div>
      </div>
    </footer>
  </div>
)
