import React from 'react'
import { hashLinks } from 'constants/links'
import { BaseContentSection } from 'components/ui'
import vietnamRussia from 'assets/images/vietnam-russia.svg'

const DeliveryFromVietnam = () => (
  <BaseContentSection
    id={hashLinks.vietnamDelivery.link.replace('#', '')}
    className='offset-top-120 offset-md-top-140'
    img={vietnamRussia}
    title={`Доставка посылок\nиз Вьетнама в Россию`}
    subtitle='Доставка для частных лиц и компаний'
  />
)

export default DeliveryFromVietnam
