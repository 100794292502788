import React from 'react'
import { hashLinks } from 'constants/links'
import { BaseContentSection } from 'components/ui'
import turkeyRussia from 'assets/images/turkey-russia.svg'

const DeliveryFromTurkey = () => (
  <BaseContentSection
    id={hashLinks.turkeyDelivery.link.replace('#', '')}
    img={turkeyRussia}
    title={`Доставка посылок из Турции\nв Россию и страны ЕАЭС`}
    subtitle={`Доставим документы и посылки до двери получателя\nот\xa0$5 и полной гарантией в срок от\xa03-х дней`}
    description='Доставка рассчитывается из города Стамбул до Москвы. Забор по Турции и доставка по России оплачиваются дополнительно. При необходимости возможна дополнительная упаковка. Сопровождение на каждом этапе доставки'
  />
)

export default DeliveryFromTurkey
