import React from 'react'
import {
  DeliveryFromTurkey,
  Calculator,
  Carousel,
  DeliveryFromVietnam,
  VietnamDeliveryAdvantages,
  Accordion
} from 'components/mainPage'

const Home = () => (
  <>
    <DeliveryFromTurkey />
    <Accordion />
    <Carousel />
    <DeliveryFromVietnam />
    <VietnamDeliveryAdvantages />
    <Calculator />
  </>
)

export default Home
