import React, { SVGProps, useState } from 'react'
import cx from 'classnames'
import { useWindowSize } from 'hooks'
import { Button, Container, Text } from 'components/ui'
import { CallbackModal } from 'components/modal'
import styles from './styles.module.scss'

interface BaseContentSectionProps {
  id?: string
  img: string | React.FC<SVGProps<SVGElement>> | undefined
  title: string
  subtitle: string
  description?: string
  className?: string
}

export const BaseContentSection: React.FC<BaseContentSectionProps> = ({ id, img, title, subtitle, description, className }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { deviceWidth } = useWindowSize()

  return (
    <Container id={id} className={className}>
      <div className={styles['base-content-section']}>
        <img src={img as string} className={styles['base-content-section__img']} alt='' />
        <Text
          as='h1'
          align='center'
          family='secondary'
          className='offset-top-32'
          whiteSpace='pre-line'
        >
          {title}
        </Text>
        <Text
          as='p'
          align='center'
          size='lg'
          className={cx('offset-top-24 offset-sm-top-32', styles['base-content-section__subtitle'])}
          whiteSpace={deviceWidth === 'small' ? 'initial' : 'pre-line'}
        >
          {subtitle}
        </Text>
        <Button
          className='offset-top-24 offset-sm-top-32 offset-md-top-44'
          onClick={() => setIsOpenModal(true)}
        >
          Узнать стоимость
        </Button>
        {description && (
          <Text
            as='p'
            align='center'
            size='lg'
            className={cx('offset-top-24 offset-sm-top-44 offset-lg-top-72', styles['base-content-section__description'])}
          >
            {description}
          </Text>
        )}
      </div>
      <CallbackModal isOpen={isOpenModal} closeHandler={() => setIsOpenModal(false)} />
    </Container>
  )
}
