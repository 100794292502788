import { RUSSIA_PHONE } from "constants/const";
import { Text } from 'components/ui'
import styles from './styles.module.scss'

const SocialLinks = () => (
  <ul className={styles['social-links']}>
    <li>
      <a href={`tel:${RUSSIA_PHONE}`}>
        <Text size='sm' family='secondary'>
          {RUSSIA_PHONE}
        </Text>
      </a>
    </li>
    {/*<li>*/}
    {/*  <a href={`tel:${TURKEY_PHONE}`}>*/}
    {/*    <Text size='sm' family='secondary'>*/}
    {/*      {TURKEY_PHONE}*/}
    {/*    </Text>*/}
    {/*  </a>*/}
    {/*</li>*/}
  </ul>
)

export default SocialLinks
