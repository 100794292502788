import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import { useWindowSize } from 'hooks'
import { Container, Text } from 'components/ui'
import CarouselArrows from './CarouselArrows'
import styles from './styles.module.scss'
import 'slick-carousel/slick/slick.css'
import 'swiper/css'
import 'swiper/css/autoplay'

const Carousel = () => {
  const [sliderIndex, setSliderIndex] = useState(0)
  const imageSliderRef = useRef<SwiperRef>(null)
  const textSliderRef = useRef<SwiperRef>(null)
  const { deviceWidth } = useWindowSize()

  const handlePrev = useCallback(() => {
    if (!imageSliderRef?.current || !textSliderRef?.current) return
    imageSliderRef.current.swiper.slidePrev()
    textSliderRef.current.swiper.slidePrev()
  }, [])

  const handleNext = useCallback(() => {
    if (!imageSliderRef?.current || !textSliderRef?.current) return
    imageSliderRef.current.swiper.slideNext()
    textSliderRef.current.swiper.slideNext()
  }, [])


  useEffect(() => {
    if (textSliderRef.current && imageSliderRef.current) {
      imageSliderRef.current.swiper.slideTo(0)
      textSliderRef.current.swiper.slideTo(0)
    }
  }, [])

  return (
    <Container className='offset-top-120 offset-md-top-140'>
      <div className={styles.carousel}>
        <div className={styles.left}>
          <Swiper
            ref={textSliderRef}
            modules={[Autoplay]}
            allowTouchMove={false}
            noSwiping={true}
            autoplay={{ delay: 5000 }}
            onSlideChange={({ realIndex }: { realIndex: number }) => setSliderIndex(realIndex)}
            // loop
          >
            {new Array(11).fill('').map((_, i) => (
              <SwiperSlide key={i}>
                <Text size='xl' family='secondary' whiteSpace={deviceWidth !== 'small' ? 'initial' : 'pre-line'}>
                  {'Текстиль.\nДоставка Турция – Россия'}
                </Text>
                <Text
                  as='p'
                  size='lg'
                  className='offset-top-16 offset-md-top-32'
                  whiteSpace={deviceWidth !== 'small' ? 'initial' : 'pre-line'}
                >
                  {'Запрос клиента, срочная доставка коммерческой партии текстиля. Вес 128 кг. Авиадоставка. Срок доставки 5 дней до\xa0Москвы.'}
                </Text>
              </SwiperSlide>
            ))}
          </Swiper>
          <CarouselArrows onPrev={handlePrev} onNext={handleNext} activeSlider={sliderIndex + 1} sliderCount={11} />
        </div>
        <div className={styles.right}>
          <Swiper
            ref={imageSliderRef}
            modules={[Autoplay]}
            className={styles.slider}
            noSwiping={true}
            allowTouchMove={false}
            slidesPerView={1}
            autoplay={{ delay: 5000 }}
            // loop
          >
            {new Array(11).fill('').map((_, i) => (
              <SwiperSlide key={i}>
                <img src={`/s${i + 1}.jpeg`} alt='' className={styles.carousel__image} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Container>
  )
}

export default Carousel
