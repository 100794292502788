import React from 'react'
import { Link } from 'react-router-dom'
import { useWindowSize } from 'hooks'
import SocialLinks from './SocialLinks'
import MenuLinks from './MenuLinks'
import logoImg from 'assets/images/logo.svg'
import styles from './styles.module.scss'

export const Header = () => {
  const { deviceWidth } = useWindowSize()

  return (
    <div className={styles['header-wrapper']}>
      <header className={styles.header}>
        <Link to='/'>
          <img className={styles.header__img} src={logoImg as string} alt='logo' />
        </Link>
        <MenuLinks />
        {deviceWidth !== 'small' && <SocialLinks />}
      </header>
    </div>
  )
}
