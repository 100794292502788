import { Collapse, Container, Text } from 'components/ui'
import styles from './accordion.module.scss'

export const Accordion = () => (
  <Container className='offset-top-56'>
    <Collapse className={styles.accordion}>
      <Collapse.Panel index={0} label='Автоперевозки' isArrowMoving>
        <div className={styles.accordion__content}>
          <Text size='lg'>Осуществляем автомобильные грузоперевозки из&nbsp;Турции в&nbsp;Россию и&nbsp;Казахстан.
            Доставка по всей России и&nbsp;Казахстану.</Text>
          <Text as='h5' className='offset-top-16 offset-sm-top-24'>
            Этапы автоперевозки
          </Text>
          <Text as='p' size='lg' className='offset-top-8 offset-sm-top-16'>
            Перевозка автомобильным транспортом состоит из этапов:
          </Text>
          <ul>
            <Text as='li' size='lg'>Адресный забор груза или прием и оформление на складе компании</Text>
            <Text as='li' size='lg'>Подготовка к автоперевозке, упаковка и маркировка грузов уникальным кодом</Text>
            <Text as='li' size='lg'>Погрузо-разгрузочные работы на складе</Text>
            <Text as='li' size='lg'>Выбор оптимального маршрута</Text>
            <Text as='li' size='lg'>Перевозка груза</Text>
            <Text as='li' size='lg'>Оповещение клиентов о прибытии грузов</Text>
            <Text as='li' size='lg'>Доставка груза по адресу получателя</Text>
          </ul>
          <Text as='h5' className='offset-top-16 offset-sm-top-24'>
            Дополнительные услуги
          </Text>
          <ul className='offset-top-8 offset-sm-top-16'>
            <Text as='li' size='lg'>
              Адресные забор и доставка груза.При заказе дополнительной услуги «Забор груза» мы заберем груз по
              указанному вами адресу в городе отправления и доставим непосредственно в руки получателю
            </Text>
            <Text as='li' size='lg'>
              Упаковка. Чтобы защитить хрупкий груз на время грузовой автомобильной перевозки, мы предлагаем более 10
              видов дополнительной упаковки
            </Text>
            <Text as='li' size='lg'>
              Полная гарания груза в случае повреждения
            </Text>
          </ul>
        </div>
      </Collapse.Panel>
      <Collapse.Panel index={1} label='Авиаперевозки' isArrowMoving>
        <div className={styles.accordion__content}>
          <Text size='lg'>
            Для тех, кто ценит время и деньги. Быстрая доставка, высокий уровень сервиса и доставка по всей России.
          </Text>
          <Text as='h5' className='offset-top-16 offset-sm-top-24'>
            Экспресс
          </Text>
          <ul className='offset-top-8 offset-sm-top-16'>
            <Text as='li' size='lg'>Максимальный вес одного груза: 10 кг</Text>
            <Text as='li' size='lg'>Максимальные габариты одного грузового места: 40 см</Text>
            <Text as='li' size='lg'>Доступен режим доставки: Дверь - Дверь</Text>
          </ul>
          <Text as='h5' className='offset-top-16 offset-sm-top-24'>
            Экспресс МАХ
          </Text>
          <ul className='offset-top-8 offset-sm-top-16'>
            <Text as='li' size='lg'>Максимально допустимый вес одного груза: 400 кг</Text>
            <Text as='li' size='lg'>Максимально допустимый вес одного грузового места (кг): 80</Text>
            <Text as='li' size='lg'>Максимальные габариты одного грузового места (Д*Ш*В): 2 x 1 x 0,8</Text>
            <Text as='li' size='lg'>Доступен режим доставки: Дверь - Дверь</Text>
          </ul>
          <Text as='h5' className='offset-top-16 offset-sm-top-24'>
            Дополнительные услуги
          </Text>
          <ul className='offset-top-8 offset-sm-top-16'>
            <Text as='li' size='lg'>Забор/доставка груза</Text>
            <Text as='li' size='lg'>Упаковка груза</Text>
            <Text as='li' size='lg'>Страхование груза</Text>
            <Text as='li' size='lg'>Погрузочно-разгрузочные работы</Text>
            <Text as='li' size='lg'>Перевозка сопроводительных документов</Text>
            <Text as='li' size='lg'>Ответственное хранение груза</Text>
            <Text as='li' size='lg'>Внутренний учёт</Text>
          </ul>
        </div>
      </Collapse.Panel>
      <Collapse.Panel index={2} label='Перевозка сборных грузов' isArrowMoving>
        <div className={styles.accordion__content}>
          <Text size='lg'>
            Уже более 5 лет мы перевозим сборные грузы из Турции по всей России и Казахстану. LTL – это безопасно,
            экономично и удобно.
          </Text>
          <Text as='h5' className='offset-top-16 offset-sm-top-24'>
            Что такое перевозка сборных грузов?
          </Text>
          <Text as='p' size='lg' className='offset-top-16 offset-sm-top-24'>
            Это вид транспортировки, при котором различные по габариту грузы нескольких клиентов доставляются на одном
            транспортном средстве и в одном направлении.
          </Text>
          <Text size='lg'>
            Сборные перевозки позволяют значительно сократить расходы
            перевозчика, а значит, существенно снизить тарифы на доставку грузов для клиентов.
          </Text>
          <Text as='h5' className='offset-top-16 offset-sm-top-24'>
            Преимущества перевозки сборных грузов:
          </Text>
          <ul className='offset-top-8 offset-sm-top-16'>
            <Text as='li' size='lg'>
              Гарантированные минимальные сроки доставки за счет оптимизированной логистики.
            </Text>
            <Text as='li' size='lg'>
              Экономическая эффективность за счет оплаты только места, занимаемого вашим грузом.
            </Text>
            <Text as='li' size='lg'>
              Он подходит для небольших партий без требований к минимальному объему.
            </Text>
          </ul>
        </div>
      </Collapse.Panel>
    </Collapse>
  </Container>
)
