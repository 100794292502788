import React from 'react'
import { Advantage, Col, Container, Row } from 'components/ui'

const advantages = [
  {
    title: '$13 за кг',
    description: 'Разумно–обоснованная цена доставки'
  },
  {
    title: 'от 5 дней',
    description: 'Срок доставки\nдо\xa0Москвы'
  },
  {
    title: 'Дверь–дверь',
    description: `Заберем груз во Вьетнаме\nи доставим до двери в\xa0России`
  },
  {
    title: '100% гарантия',
    description: 'Все отправки застрахованы.\nПолный возврат денег в\xa0случае порчи или утери'
  }
]

export const VietnamDeliveryAdvantages = () => (
  <Container>
    <Row row={8} className='offset-top-8' small>
      {advantages.map((v) => (
        <Col key={v.title} sm={6} lg={3}>
          <Advantage {...v} />
        </Col>
      ))}
    </Row>
  </Container>
)
