export const hashLinks = {
  turkeyDelivery: {
    text: 'Доставка из Турции',
    link: '#turkey-delivery'
  },
  vietnamDelivery: {
    text: 'Доставка из Вьетнама',
    link: '#vietnam-delivery'
  }
}
